$primary-color: #e30613;
$primary-color-text: #000000;

$secondary-color: black; 
$link-color: color("grey", "lighten-2");

$highlight-color: $primary-color;

$gray-lighten-2: #e0e0e0;

$button-raised-background: $gray-lighten-2;
$button-raised-color: $primary-color;

@mixin root-vars-dasbeste {
  --color-primary: #{$primary-color} !important;
  --text-on-primary: #{$primary-color-text};
}