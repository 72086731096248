#login {
  margin: 0 auto;
  max-width: 500px;

  h1 {
    text-align: center;
  }

  .alert {
    padding: 1rem;
    background-color:$secondary-color;
    color: white;
    border-radius: .3rem;
    
    &-danger {
      background-color: lighten(#E80919, 40%);
      color: #E80919;
    }
  }
}