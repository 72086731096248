$primary-color: #ffdf6f;
$primary-color-text: #000000;

$secondary-color: #FEC300;

$link-color: color("yellow", "darken-1");

$highlight-color: darken($primary-color, 25%);

$button-raised-background: $primary-color;
$button-raised-color: $primary-color-text;

@mixin root-vars-culinarium {
  --color-primary: #{$primary-color} !important;
  --text-on-primary: #{$primary-color-text} !important;
}
.stats-channelSpecialRetail {
  display: none !important;
}