&.bu-front {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background-color: $background-front;

  a {
    color: black;
    
    &:hover,
    &:active {
      color: $secondary-color;
    }
  }

  .btn:hover {
    color: black;
  }
}

.section-header {
  padding: 0;
  margin-top: 2rem;

  .logo img {
    height: 60px;
  }

  .years {
    text-align: right;
    padding-right: 0;
    
    a {
      font-size: 25px;
      margin: 0 0 0 10px;
      color: #DBDBDB;

      &.current {
        font-size: 55px;
      }
    }
    

  }
}

.section-window {
  padding: 0;

  .container{
    background-color: white;
    padding: 3rem 4rem;
  }

  .title {
    h1 {
      color: #5D5D5D;
      font-size: 55px;
      letter-spacing: -3px;
      line-height: 1;
      margin: 0;
    }

    .sub {
      color: #5D5D5D;
      font-size: 25px;
      font-weight: lighter;
    }
  }
}

form.disabled-loading {
  .row {
    position: relative;
    filter: opacity(0.5) blur(1px);
  }
}
.modal.open.disabled-category {
    .row:nth-child(5) {
      position: relative;
      filter: opacity(0.5) blur(1px);
      &::before {
        content: '';
        position: absolute;
        top:0; left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999;
      }
  }
}
.modal.open.disabled, form.disabled {
  filter: opacity(0.5) blur(1px);
  &::before {
    content: '';
    position: absolute;
    top:0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;

  }
}
&.bu-front {

  .bu-input {
    display: flex;
    align-items: center;
    margin: .5rem 0;

    label {
      width: 265px;
      padding-right: 25px;
      color: black;
      font-size: 16px;
      font-weight: bold;

      .bu-input__required {
        color: #f00;
      }
    }

    .input-field {
      margin: 0;
      flex-basis: 45%;

      &.multiselect {
          label {
            display: block !important;
            width: 100%;
          }
      }

      label {
        display: none;
      }

      input[type=number],
      input#business_stats_turnover,
      input.product-turnover,
      #business_stats_customTurnoverField {
        text-align: right;
      }
      input#business_stats_b2b_business_plz {
        text-align: left;
      }

      input[type=range] {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          // width: attr(data-width);
          background-color: darken($primary-color, 20%);
        }
        @for $i from 1 through 100 {
          &[data-width="#{$i}"]::before {
            width: ($i * 1%);
          }
        }
      }

      // symfony input field
      div {
        ul {
          margin: 0;
          
          li {
            @extend .helper-text;
            color: rgba(#f00, 0.7);
          }
        }

        .help-text {
          display: none;
        }
      }
    }
    
    .input-field-flex {
      display:flex;
      gap: 1.4em;
      align-items: center;
      .plus, .minus {
        border: 0;
        background: transparent;
        cursor: pointer;
      }
    }
    .unit {
      padding-left: 25px;
      color: black;
      font-size: 16px;
    }

    &.bu-input__total {
      flex-wrap: wrap;
      > .border {
        border-top: 1px solid #d2d2d2;
        width: 81%;
      }
      label,
      p,
      .unit {
        padding-top: .5rem;
        min-height: 2.5rem;
      }
    }
  }

  h3 {
    margin: 3rem 0 1rem 0;
    color: #9B9B9B;
    font-size: 25px;
  }

  h2 {
    margin: 3rem 0 1rem 0;
    color: #9B9B9B;
    font-size: 35px;
  }

  .turnover-start {
    color: #f00;
  }

  .turnover-products {
    overflow: auto;

    &__hint {
      padding: .5rem;
      border: 1px solid #f00;
      background-color: lighten(#f00, 40%);
      margin-bottom: 1rem;
    }

    .btn {
      margin-bottom: 3px;
    }
  }

  table {
    td {
      max-width: 250px;
    }

    .delete-product:hover {
      color: #f00;
    }

    .turnover-products__total-row {
      border-bottom: none;
    }

    .turnover-products__total {
      font-weight: bold;
    }
    .turnover-products__total--invalid {
      color: #f00;
    }
  }

  footer {
    margin: 2rem 0 0 0;
    float: right;
    display: flex;
  }
}

.modal-product-edit {
  padding: 2rem;
  max-height: 90%;
  width: 65%;

  .close {
    position: absolute;
    top: 1rem;
    right: 0;

    i {
      font-size: 2rem;
    }
  }

  h4 {
    margin: 0 0 1rem 0;
    color: #5D5D5D;
    font-size: 55px;
  }

  .select-wrapper .dropdown-content {
    max-height: 350px;
  }
  .b2b-business-select-row .caret { display: none; }
  .choices {
    z-index: 9999;
    height: 50px;
    background: transparent;
    border: 0;
    font-size: 16px;
    line-height: 48px;
    font-family: inherit;
    color: #000;
    .choices__input { padding-left: 14px; }
    .choices__inner {
      background: transparent;
      color: #000;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      outline: none;
      height: 3rem;
      line-height: 3rem;
      width: 100%;
      font-size: 16px;
      margin: 0 0 8px;
      padding: 0;
      display: block;
      user-select: none;
      z-index: 1;
      &:hover, &:focus { background: transparent; }
    }
    .choices__item {
      span:first-child {
        display: block; color: grey;
      }
      span:last-child {
        display: none;
      }
    }
    .choices__list--dropdown .choices__item {
      &:hover, &:focus, &.highlighted { background-image: none; background-color: rgba(0,0,0,.08); }
      font-size: 16px !important;
      color: #fec300 !important;
      display: block;
      line-height: 22px !important;
      padding: 14px 16px !important;
    }
    .choices__list--single .choices__item--selectable {
      span:first-child {
        display: inline-block;
        color: lightgray;
      }
    }
  }

  .total {
    font-weight: bold;

    &--invalid {
      color: #f00 !important;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}

&.bu-front .section-footer {
  .address {
    text-align: right;
  }
}

// mobile
.container {
  width: 95%;
}
@media only screen and (min-width: 993px) {
  .container {
      width: 95%;
  }
}
@media only screen and (min-width: 601px) {
  .container {
    width: 95%;
  }
}

@media #{$medium-and-down} {
  .section-window .container {
    padding: 3rem 1rem;
  }

  .section-window .title h1 {
    font-size: 2.5rem;
  }

  .bu-front .bu-input {
    & > label {
      display: none;
    }

    .input-field div label {
      display: block;
    }
  }

  .modal-product-edit {
    height: 95%;
    top: 2% !important;
    width: 95%;
  }

  .bu-front .section-footer .col {
    text-align: center;
  }
}

.hidden { display: none !important; }

.readonly {
  .save, .delete-product { display: none !important; }
  input, select {
    border-bottom: 0 !important;
  }
}

.error-message {
  padding: 10px 20px;
  background: #ff8f8f;
  border: 1px solid d30f0f;
  border-radius: 3px;
}